.info-bar {
  width: 33%;
  height: 100vh;
  align-self: stretch;
  background-color: #383b42;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin: 50px 0px;
  max-width: 100%;
}

.info-text-div {
  width: 80%;
}

.closest-location-div {
  margin: 10px 0px;
}

.locations-select {
  max-width: 80%;
}

@media (max-width: 768px) {
  .info-bar {
    width: 100%;
    height: 150px;
  }

  .logo {
    margin: 10px 0px;
    max-height: 60%;
  }

  .info-text-div {
    display: none;
  }

  .closest-location-div {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .locations-select {
    display: none;
  }

  .info-bar-credits-div {
    display: none;
  }
}
